import React from 'react';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import badge from "../badge.png";

type BackProps={
    theme:any;
};
export default function InTheBack({theme}:BackProps)  {
    const handleRedirect = () => {
        window.open("https://www.linkedin.com/in/aleksandra-%C5%82apczuk/");
    };
    const handleGithub = () => {
        window.open("https://github.com/alapczvk");
    };
    const handleMemory = () => {
        window.open("https://afmemory.fkor.us");
    };
    const handleWorkspace = () => {
        window.open("https://afworkspace.fkor.us");
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "24px"}}>you can find me
                on:</Typography>
            <Button onClick={handleRedirect} style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
                borderRadius: "1rem",
                fontSize: "20px",
                marginBottom: "0.5rem",
                width: "80%"
            }}>Linkedin</Button>
            <Button onClick={handleGithub} style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
                borderRadius: "1rem",
                fontSize: "20px",
                width: "80%",
                marginBottom: "1rem",
            }}>GitHub</Button>
            <Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "24px"}}>my and my bf
                projects:</Typography>
            <Button onClick={handleMemory} style={{
                backgroundColor: theme.palette.primary.main,
                color:theme.palette.text.primary,
                borderRadius: "1rem",
                fontSize: "20px",
                marginBottom: "0.5rem",
                width: "80%"
            }}>memory game</Button>
            <Button onClick={handleWorkspace} style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
                borderRadius: "1rem",
                fontSize: "20px",
                width: "80%",
                marginBottom: "1rem",
            }}>workspace</Button>
            <Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "24px"}}>my badge: </Typography>
            <button onClick={(e) => {
                e.preventDefault();
                window.location.href='https://www.credly.com/badges/6ff2ede7-d65b-465e-aeb5-8280b66d6d78/public_url';
            }} style={{
                background: 'transparent',
                border: 'none',
                outline: 'none',
                padding: 0,
                cursor: 'pointer'
            }}>
                <img src={badge} alt="badge" style={{ width: '35%', height: '35%', borderRadius: '1rem' }} />
            </button>
            <Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "20px"}}> waiting to add here more in the future ;) </Typography>
        </div>
    );
};

