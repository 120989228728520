import React, {useEffect, useState} from 'react';
import { Typography} from "@mui/material";
import RestApi from "./RestApi";

type LeftProps = {
    theme: any;
}
export default function Left({theme}: LeftProps) {
    const current = new Date();
    const date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
    // const day= current.getDay();
    const currday = (dayInDig: any) => {
        if (dayInDig === 1) {
            return "Monday";
        } else if (dayInDig === 2) {
            return "Tuesday";
        } else if (dayInDig === 3) {
            return "Wednesday";
        } else if (dayInDig === 4) {
            return "Thursday";
        } else if (dayInDig === 5) {
            return "Friday";
        } else if (dayInDig === 6) {
            return "Saturday";
        } else {
            return "Sunday";
        }
    }
    const [offset, setOffset] = useState(0);
    const [direction, setDirection] = useState(1); // 1 - w dół, -1 - w górę

    useEffect(() => {
        const interval = setInterval(() => {
            const newOffset = offset + direction * 5;

            if (newOffset <= 0 || newOffset >= 480) {
                setDirection(prevDirection => -prevDirection);
            } else {
                setOffset(newOffset);
            }
        }, 200);
        return () => clearInterval(interval);
    }, [offset, direction]);

    return (
        <div>
            <div style={{
                backgroundColor: theme.palette.primary.main,
                margin: '1rem',
                padding: '0.4rem',
                borderRadius: '1rem',
                transform: `translateY(${offset}px)`,
                transition: 'transform 0.3s'
            }}>
                <Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "20px"}}>Today's
                    date: {date} </Typography>
                <Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "20px"}}>It
                    is {currday(current.getDay())} today. </Typography>
                <RestApi theme={theme}/>
                {/*<Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "20px"}}> I am studying ICT at AGH University of Science and Technology. I'm on my third year. During my spare time I ski, read thrillers and practice sports e.g fitness and swimming. </Typography>*/}
            </div>
        </div>
    );
};

