import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

type FootProps={
    theme:any;
};
export default function Footer({theme}:FootProps) {
    return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '20vh',
                }}
            >
                <CssBaseline />
                <Box style={{backgroundColor: theme.palette.primary.main}}
                    component="footer"
                    sx={{
                        py: 2,
                        px: 2,
                        mt: 'auto',
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography style={{color:theme.palette.text.primary}}>
                            {'© Aleksandra Lapczuk '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>
                    </Container>
                </Box>
            </Box>
    );
}