import React, { useState } from 'react';
import { Fade, Typography } from '@mui/material';
import image from '../image.jpg';
import InTheBack from "./InTheBack";

type RightProps={
    theme:any;
};

const Icon = ({ theme }: { theme: any }) =>(
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <button style={{
                background: 'transparent',
                border: 'none',
                outline: 'none',
                padding: 0,
                cursor: 'pointer'
            }}>
                <img src={image} alt="me" style={{ width: '80%', height: 'auto', borderRadius: '1rem' }} />
            </button>
            <Typography style={{ color: theme.palette.text.primary, fontFamily: "Gill Sans" }}>click me!</Typography>
        </div>

);

export default function Right({theme}:RightProps) {
    const [checked, setChecked] = useState(true);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return (
        <div style={{ height: '80vh', position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: checked ? 'auto' : 'none' }}>
                <Fade in={checked} timeout={1000}>
                    <div onClick={handleChange} style={{ cursor: 'pointer' }}>
                        <Icon theme={theme}/>
                    </div>
                </Fade>
            </div>
            <div style={{ opacity: checked ? 0 : 1, height: '100%', transition: 'opacity 1s', pointerEvents: checked ? 'none' : 'auto' }}>
                <InTheBack  theme={theme}/>
            </div>
        </div>
    );
}
