import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Typography} from "@mui/material";

type RestProps={
    theme: any;
}
export default function RestApi({theme}: RestProps) {
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://weather.fkor.us/api.php');
                setData(response.data);
            } catch (error) {
                console.error('Błąd podczas pobierania danych:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div>{data && (
            <Typography style={{color: theme.palette.text.primary, fontFamily: "Gill Sans", fontSize: "20px"}}>Temperature in Lesser Poland Voivodeship: {data.temperature_outdoor} °C </Typography>
        )}

            </div>
    );
}
