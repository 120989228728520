import React, { useState } from 'react';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import { Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Right from "./components/Right";
import Left from "./components/Left";
export default function App() {
    const [themeLight, setThemeType] = useState(true);
    const theme = createTheme({
        palette: {
            mode: themeLight ? "light" : "dark",
            primary: {
                main: themeLight ? 'rgb(171, 136, 164)' : 'rgb(106,0,84)',
            },
            secondary: {
                main: themeLight ? 'rgb(165, 149, 161)' : 'rgb(140,123,141)',
            },
            text:{
                primary: themeLight ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            },
        },
    });

    function handleThemeChange() {
        setThemeType(!themeLight);
    }
    return (
        <ThemeProvider theme={theme}>
            <div id="back">
                <Header onThemeChange={handleThemeChange} themeLight={!themeLight} theme={theme} />
                <Grid container spacing={2} columns={16} justifyContent="space-between" >
                    <Grid
                        style={{
                            backgroundColor: theme.palette.secondary.main, // Access 'palette' property from the 'theme' object
                            marginTop: '1rem',
                            marginLeft: '2rem',
                            padding: '0.2rem',
                            borderRadius: '1rem',
                            minHeight: '80vh',
                        }}
                        xs={8}
                    >
                        <div>
                            <Left theme={theme}/>
                        </div>
                    </Grid>
                    <Grid style={{ backgroundColor: theme.palette.secondary.main, margin: '1rem', padding: '0.2rem', borderRadius: '1rem' }} xs={6}>
                        <Right theme={theme}/>
                    </Grid>
                </Grid>
                <Footer theme={theme}/>
            </div>
        </ThemeProvider>
    );
}
